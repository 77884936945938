import React, { useState } from 'react';
import { ITripSearchData } from '_models/vendor.interface';
import './SimulateComp.scss'
import CancelIcon from '@mui/icons-material/Cancel';
import DisplayResponse from '../../../../_components/DisplayResponse/DisplayResponse';

interface ISimulateBpProps {
    response: any;
    onCloseModal: () => void;
}

const SimulateBp: React.FC<ISimulateBpProps> = (props: ISimulateBpProps) => {
    const { response, onCloseModal } = props;

    return (
        <div className='simulate-trip-container'>
            <div className='inner-wrapper form-wrapper'>
                <div className='header-data'>
                    <h3>Simulation Response</h3>
                    <div>
                        <CancelIcon className="close-modal" onClick={onCloseModal} />
                    </div>
                </div>
                <div className='cust-form-group' style={{ marginTop: '20px' }}>
                    <div>
                        <label>Response</label>
                    </div>
                    <div className="data-info-wrapper">
                        <DisplayResponse data={response} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SimulateBp;