import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  ColorPicker,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Segmented,
  Select,
  Slider,
  Space,
  Switch,
  TreeSelect,
  Upload,
} from 'antd';
import { UtilSvc } from '_services/util.service';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { debounce } from 'lodash';
import { RefDataSvc } from '_services/refData.service';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

interface IProductFields {
  label: string;
  name: string;
  fieldType: string;
  defaultOperator: any;
}



const FormBuilder = (props: { fields: IProductFields[], data?: any, segments?: any, onFormSubmit: any, onFormChange: any, emitFormDataFormat?: any, layout?: 'horizontal' | 'vertical', primaryButtonTitle?: string, extraButtons?: string[], resetFlag: boolean }) => {
  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [customForm, setCustomForm] = useState<IProductFields[]>([]);
  const [columnLayout, setColumnLayout] = useState<any>({ labelCol: { span: 24 }, wrapperCol: { span: 24 } });
  const [form] = Form.useForm();
  const [dataFormat, setDataFormat] = useState<any>({});
  const [segmentName, setSegmentName] = useState<any>(null);


  const _setColumnLayout = (layout: any) => {
    if (layout === 'horizontal') {
      setColumnLayout({ labelCol: { span: 4 }, wrapperCol: { span: 20 } })
    } else {
      setColumnLayout({ labelCol: { span: 24 }, wrapperCol: { span: 24 } });
    }
  }

  const _getRule = (obj: any) => {
    let rulesObj: any = {};
    if (obj.required)
      rulesObj = { required: obj.required, message: `Please input valid ${obj.label}!` };
    if (obj.extra?.rules) {
      rulesObj = { ...rulesObj, ...obj.extra.rules };
    }
    return Object.keys(rulesObj).length > 0 ? [rulesObj] : [];
  }

  const _selectSegment = (segmentName?: string) => {
    _onReset();
    if (!segmentName)
      segmentName = props.segments[0].name;
    let fields = _getFieldsBySegment(segmentName as string);
    setSegmentName(segmentName);
    setCustomForm(fields);
  }

  const _getFieldsBySegment = (segname: string) => {
    let tempFields: any = [];
    let segment = props.segments.find((el: any) => el.name === segname).segment;
    segment.forEach((fname: any) => {
      let field = props.fields.find((el: any) => el.name === fname);
      if (field)
        tempFields.push(field);
    });
    return tempFields;
  }

  useEffect(() => {
    if (props.fields?.length > 0) {
      if (props.segments?.length > 0) {
        _selectSegment();
      } else {
        setCustomForm(props.fields);
        setSegmentName(null);
      }
    }
  }, [props.fields, props.segments]);

  useEffect(() => {
    _setColumnLayout(props.layout);
  }, [props.layout])

  useEffect(() => {
    form.setFieldsValue(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.resetFlag) {
      _onReset();
    }
  }, [props.resetFlag]);

  const _onChange = (obj: any, e: any) => {
    // console.log({ obj, e });
    let data: any = null;
    if (e)
      switch (obj.fieldType) {
        case 'DATE':
        case 'NUMBER':
        case 'DROPDOWN':
        case 'AUTOCOMPLETE':
        case 'SWITCH':
          data = e;
          if (obj.fieldType === 'DATE' && obj.extra.range && data?.at(1)) {
            data[1] = data[1].clone().endOf('day');
            // console.log(data[1]);
          }
          break;
        default:
          data = e.target.value;
          break;
      }
    props.onFormChange({ fieldObj: obj, value: data });
    let values = { ...dataFormat, [obj.name]: data };
    setDataFormat(values);
    props.emitFormDataFormat(values);
  }
  const _onFinish = (values: any) => {
    // console.log({ data: values, segment: segmentName });
    props.onFormSubmit('submit', { data: values, segment: segmentName });
  }
  const _onReset = () => {
    form.resetFields();
  };
  const _extraBtnClick = (btn: string) => {
    props.onFormSubmit(btn);
  }

  return (
    <>
      {
        props.segments && props.segments.length > 0 &&
        <Flex justify={'center'} align={'center'} style={{ marginBottom: 30 }}>
          <Segmented<string>
            options={props.segments.map((s: any) => s.name)}
            onChange={(value) => _selectSegment(value)}
          />
        </Flex>
      }

      <Form
        form={form}
        labelCol={columnLayout.labelCol}
        wrapperCol={columnLayout.wrapperCol}
        layout={props.layout ? props.layout : "vertical"}
        disabled={componentDisabled}
        onFinish={_onFinish}
      //   style={{ maxWidth: 600 }}
      >
        {
          customForm && customForm.map((obj: any, index: number) => (
            <Form.Item
              label={obj.label}
              name={obj.name}
              key={obj.name + obj.label}
              rules={_getRule(obj)}
            >
              {obj.fieldType === 'PASSWORD' && <Input.Password onChange={(e) => _onChange(obj, e)} />}
              {obj.fieldType === 'TEXT' && <Input onChange={(e) => _onChange(obj, e)} />}
              {obj.fieldType === 'TEXTAREA' && <Input.TextArea onChange={(e) => _onChange(obj, e)} />}
              {obj.fieldType === 'DATE' &&
                (obj.extra?.range
                  ? <RangePicker onChange={(e) => _onChange(obj, e)} />
                  : <DatePicker onChange={(e) => _onChange(obj, e)} />)
              }
              {obj.fieldType === 'CHECKBOX' && <Checkbox.Group options={obj.options} onChange={(e) => _onChange(obj, e)} />}
              {obj.fieldType === 'DROPDOWN' && <CustomDropDown obj={obj} onChange={_onChange} />}
              {obj.fieldType === 'NUMBER' && <InputNumber onChange={(e) => _onChange(obj, e)} />}
              {obj.fieldType === 'RADIO' && <CustomRadioGroup obj={obj} onChange={_onChange} />}
              {obj.fieldType === 'AUTOCOMPLETE' && <CustomAutoComplete obj={obj} onChange={_onChange} />}
            </Form.Item>
          ))
        }

        <Flex justify={'center'} align={'center'}>
          <Space>
            <Button type="primary" htmlType="submit">{props.primaryButtonTitle ? props.primaryButtonTitle : 'Submit'}</Button>
            <Button type="default" onClick={_onReset}>Reset</Button>
            {
              props.extraButtons && props.extraButtons.map((btn: any, i: number) => (
                <Button key={"button-" + i} type="default" onClick={() => _extraBtnClick(btn)}>{btn}</Button>
              ))
            }
          </Space>
        </Flex>

      </Form>
    </>
  );
}

export default FormBuilder;




const CustomAutoComplete = (props: { obj: any, onChange: any }) => {
  const refDataSvc = RefDataSvc.getInstance();
  const [options, setOptions] = useState<any>([]);

  const _getLocalOptions = (text: string) => {
    let dataset = options.length === 0 ? props.obj.extra.values : options;
    let opt = dataset.filter((el: any) => el[props.obj.extra.labelfield].toLowerCase().includes(text.toLowerCase()));
    return _transformOptions(opt, props.obj.extra.valuefield);
  }
  const _getRemoteOptions = (text: string) => {
    debouncedSearch(text);
  }
  const debouncedSearch = debounce((query: string) => {
    handleSearch(query);
  }, 300);
  const handleSearch = (query: string) => {
    let url = refDataSvc.fetchSearchAirlineURL(query);
    fetch(url).then(response => response.json()).then((data: any[]) => {
      setOptions(_transformOptions(data, props.obj.extra.valuefield, props.obj.extra.labelfield));
    });
  }


  const getPanelValue = (text: string) => {
    if (text.length > 2) {
      return props.obj.extra.sourcetype === 'LOCAL' ? _getLocalOptions(text) : _getRemoteOptions(text);
    }
    else
      return [];
  }
  const _transformOptions = (data: any, valuefield: string, labelfield?: string) => {
    data.forEach((obj: any) => {
      obj['value'] = obj[valuefield];
      if (labelfield) obj['label'] = obj[labelfield];
    });
    return data;
  }

  const _onSelectOption = (e: any) => {
    let d = options.find((el: any) => el.value === e);
    props.onChange(props.obj, d);
  }
  return (
    <AutoComplete
      options={options}
      onSelect={(e) => _onSelectOption(e)}
      onSearch={(text) => setOptions(getPanelValue(text))}
      placeholder="Type here.."
    />
  );
}


const CustomRadioGroup = (props: { obj: any, onChange: any }) => {
  const [options, setOptions] = useState<any>([]);
  const _getRemoteOptions = (url: string) => {
    fetch(url).then(response => response.json()).then(data => {
      return data;
    });
  }
  const _transformOptions = (data: any, valuefield: string) => {
    data.forEach((obj: any) => {
      obj['value'] = obj[valuefield];
    });
    return data;
  }
  useEffect(() => {
    if (props.obj?.extra) {
      props.obj.extra.sourcetype === 'LOCAL'
        ? (props.obj.extra.datatype === 'object'
          ? setOptions(_transformOptions(props.obj.extra.values, props.obj.extra.valuefield))
          : setOptions(props.obj.extra.values.map((el: any) => ({ label: el, value: el }))))
        : setOptions(_getRemoteOptions(props.obj.extra.url));
    }
  }, [props.obj]);

  return (
    <>
      {
        props.obj.extra?.optiontype === 'button'
          ? <Radio.Group options={options} onChange={(e) => props.onChange(props.obj, e)} optionType={props.obj.extra.optiontype} buttonStyle="solid" />
          : <Radio.Group options={options} onChange={(e) => props.onChange(props.obj, e)} />
      }
    </>
  );
}

const CustomDropDown = (props: { obj: any, onChange: any }) => {
  const [options, setOptions] = useState<any>([]);
  const _getRemoteOptions = (url: string) => {
    fetch(url).then(response => response.json()).then(data => {
      return data;
    });
  }
  const _transformOptions = (data: any, valuefield: string) => {
    data.forEach((obj: any) => {
      obj['value'] = obj[valuefield];
    });
    return data;
  }
  const _getPlacholder = () => {
    return `select ${props.obj.label}`;
  }

  useEffect(() => {
    if (props.obj?.extra) {
      props.obj.extra.sourcetype === 'LOCAL'
        ? (props.obj.extra.datatype === 'object'
          ? setOptions(_transformOptions(props.obj.extra.values, props.obj.extra.valuefield))
          : setOptions(props.obj.extra.values.map((el: any) => ({ label: el, value: el }))))
        : setOptions(_getRemoteOptions(props.obj.extra.url));
    }
  }, [props.obj]);
  return (
    <>
      {options && options.length > 0 &&
        <Select
          mode={props.obj.extra?.multi ? "multiple" : undefined}
          allowClear
          options={options}
          placeholder={_getPlacholder()}
          optionLabelProp={props.obj.extra?.labelfield}
          onChange={(e) => props.onChange(props.obj, e)} />}
    </>
  );
}