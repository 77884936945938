import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";
import { GridPaginationModel } from "@mui/x-data-grid";

export class CommSenderSvc extends UrlProviderService {
    private static instance: CommSenderSvc;
    private constructor() {
        super('comm-sender-service-api');
    }
    public static getInstance(): CommSenderSvc {
        if (!this.instance) {
            this.instance = new CommSenderSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);

    searchByDateRange(startDate: string, endDate: string, page: GridPaginationModel) {
        const uri = this.generateUrl(`notifications/bydaterange?page=${page.page}&size=${page.pageSize}&startDate=${startDate}&endDate=${endDate}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    searchByBookingNumAndFlight(bookingnum: string, airline: string, flightnum: string, page: GridPaginationModel) {
        let q = `page=${page.page}&size=${page.pageSize}&bookingNo=${bookingnum}`;
        airline && (q += `&airline=${airline}`);
        flightnum && (q += `&flightNo=${flightnum}`);
        const uri = this.generateUrl(`notifications/bybookingno?${q}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
}