import React, { createContext, useContext, useEffect, useState } from 'react';
import { IProductsFeaturesModel, IAuthToken } from '_models/common.interface';
import { IVendorProfile } from '_models/vendor.interface';
import { AppConstants } from '_constants/App.constants';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
import { StorageProvider } from '_services/storageProvider.service';
import { AuthSvc } from '_services/auth.service';
import { deepCopy } from '_services/utils.service';
import { VendorConstants } from '_constants/Vendor.constants';

interface IVendorDataContext {
    vendorProfile: IVendorProfile | undefined;
    tokenPayload: IAuthToken | null;
    productsFeatures: IProductsFeaturesModel | undefined;
    vendorDisplayOptions: IDisplayOptions;
}
interface IDisplayOptions {
    productIdTravel: boolean
    emailIdPricing: boolean;
    emailIdAnalytics: boolean;
    emailIdUpcomingTrips: boolean;
    emaildUpcomingTripsSmsFeature: boolean;
    emailIdSearchBooking: boolean;
    emailIdBillingReport: boolean;
    emailIdSendSms: boolean;
    emailIdSearchSms: boolean;
}

const DEFAULT_DEISPALY_OPTIONS: IDisplayOptions = {
    productIdTravel: false,
    emailIdPricing: false,
    emailIdAnalytics: false,
    emailIdUpcomingTrips: false,
    emaildUpcomingTripsSmsFeature: false,
    emailIdSearchBooking: false,
    emailIdBillingReport: false,
    emailIdSendSms: false,
    emailIdSearchSms: false,
}

const VendorDataContext = createContext<IVendorDataContext>({
    vendorProfile: undefined,
    tokenPayload: null,
    productsFeatures: undefined,
    vendorDisplayOptions: deepCopy(DEFAULT_DEISPALY_OPTIONS),
});
export const useVendorDataContext = () => useContext<IVendorDataContext>(VendorDataContext);

interface IVendorDataProviderProps {
    children: React.ReactNode;
}
export const VendorDataProvider: React.FC<IVendorDataProviderProps> = ({ children }: IVendorDataProviderProps) => {
    const [vendorProfile, setVendorProfile] = useState<IVendorProfile | undefined>();
    const [tokenPayload, setTokenPayload] = useState<IAuthToken | null>(null);
    const [productsFeatures, setProductsFeatures] = useState<IProductsFeaturesModel | undefined>();
    const [displayOptions, setDisplayOptions] = useState<IDisplayOptions>(deepCopy(DEFAULT_DEISPALY_OPTIONS));

    const vendorMgmntSvc = VendorMgmntSvc.getInstance();
    const storageSvc = StorageProvider.getStorage();
    const authSvc = AuthSvc.getInstance();

    useEffect(() => {
        const token: string | null = storageSvc.get(AppConstants.TOKEN.AUTH);
        if (token) {
            const decodedToken: IAuthToken | null = vendorMgmntSvc.getTokenData();
            setTokenPayload(decodedToken);
            if (decodedToken?.scope?.toLowerCase() === AppConstants.ROLES.VENDOR?.toLowerCase()) {
                vendorMgmntSvc.vendorInfo().then((res: any) => {
                    if (res?.data) {
                        setVendorProfile(res.data);
                    }
                });
            }
        } else {
            // for embedded x_alkey is used
            const x_alkey = storageSvc.get(AppConstants.X_ALKEY);
            if (x_alkey) {
                vendorMgmntSvc.vendorInfo().then((res: any) => {
                    if (res?.data) {
                        setVendorProfile(res.data);
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (vendorProfile && tokenPayload) {
            setDisplayOptions(prev => ({
                ...prev,
                productIdTravel: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.TRAVEL_ID, 'productId'),
                emailIdPricing: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_PRICING, 'emailId'),
                emailIdAnalytics: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_ANALYTICS, 'emailId'),
                emailIdUpcomingTrips: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_UPCOMING_TRIPS, 'emailId'),
                emaildUpcomingTripsSmsFeature: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_UPCOMING_TRIPS_SMS_FEATURE, 'emailId'),
                emailIdSearchBooking: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_SEARCH_BOOKING, 'emailId'),
                emailIdBillingReport: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_BILLING_REPORT, 'emailId'),
                emailIdSendSms: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_SEND_SMS, 'emailId'),
                emailIdSearchSms: vendorMgmntSvc.vendorRouteGuard(vendorProfile, tokenPayload, VendorConstants.VENDOR_ROUTE_ACCESS.EMAIL_ID_SEARCH_SMS, 'emailId'),
            }))
        }
    }, [vendorProfile, tokenPayload]);


    return (
        <VendorDataContext.Provider
            value={{
                vendorProfile,
                tokenPayload,
                productsFeatures,
                vendorDisplayOptions: displayOptions,
            }}
        >
            {children}
        </VendorDataContext.Provider>
    );
};