import React, { useState, useEffect } from 'react';
import { useToast } from '_contexts/ToastContext';
import './TripBpViewer.scss';
// import '_modules/CheckinOperatorModule/_components/BPViewer/BPViewer.scss'
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';
import { CheckinSvc } from '_services/checkin.service';
import { BPType, IBPViewerFlag, ICheckinContent, ICheckinData, IDMSDataMod } from '_models/checkin.interface';
import { DMSSvc } from '_services/dms.service';
import { IPassenger } from '_models/vendor.interface';

type ITripBpViewerProps = {
    displayFlag: boolean;
    passengerInfo: IPassenger | undefined | null;
    onUpdateDisplayFlag: (display: boolean) => void;
};

const TripBpViewer: React.FC<ITripBpViewerProps> = ({ displayFlag, passengerInfo, ...props }: ITripBpViewerProps) => {
    const dmsSvc = DMSSvc.getInstance();
    const toast = useToast();
    const [width, setWidth] = useState(window.innerWidth);
    const [pdfUrl, setPDFUrl] = useState<string>('');

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (displayFlag && passengerInfo?.chkData?.pass?.dmscode) {
            setPDFUrl(dmsSvc.getDocumentUrl(passengerInfo.chkData.pass.dmscode));
        } else {
            setPDFUrl('');
        }
    }, [displayFlag, passengerInfo]);

    const closeViewer = () => {
        props.onUpdateDisplayFlag(false);
    }

    const openPDF = () => {
        window.open(`${pdfUrl}`, '_blank');
    }
    const handleResize = () => {
        setWidth(window.innerWidth);
    }
    const getStyle = () => {
        let viewerWidth: string = '50vw';
        if (width <= 600) viewerWidth = `${(width - 40)}px`;
        return { width: viewerWidth };
    }

    return (
        <>
            <div className='bg-cover' onClick={closeViewer}></div>
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                <div className='bp-viewer-container' style={getStyle()}>
                    <div className='header'>
                        <CancelIcon onClick={closeViewer} style={{ cursor: 'pointer' }} />
                        {/* PDF Selected */}
                    </div>
                    <div className='content'>
                        {/* <div className='data-container'>
                            <div className='data-grp'>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        Airport:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.from.iata} - {passengerInfo?.chkData?.to.iata}
                                    </div>
                                </div>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        Flight:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.airline.iata} {passengerInfo?.chkData?.flightnum}
                                    </div>
                                </div>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        Departure Local:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.dept.date} {passengerInfo?.chkData?.dept.time}
                                    </div>
                                </div>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        First Name:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.passenger.firstname}
                                    </div>
                                </div>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        Last name:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.passenger.lastname}
                                    </div>
                                </div>
                                <div className='data-wrapper'>
                                    <div className='data-key'>
                                        PNR:
                                    </div>
                                    <div className='data-value'>
                                        {passengerInfo?.chkData?.pnr}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='pdf-container'>
                            {
                                !!pdfUrl &&
                                <Viewer fileUrl={pdfUrl} />
                            }
                        </div>
                    </div>
                </div>
            </Worker>
        </>
    );
};

export default TripBpViewer;
