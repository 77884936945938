import { useGridApiContext, gridPageSelector, useGridSelector, gridPageCountSelector } from '@mui/x-data-grid-premium';
import { Pagination } from '@mui/material';
import React from 'react';

interface ICustomGridPaginationProps {
    isZeroBased: boolean;
}

const CustomGridPagination: React.FC<ICustomGridPaginationProps> = ({ isZeroBased }: ICustomGridPaginationProps) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const currentPage = isZeroBased ? page + 1 : page;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPage = isZeroBased ? value - 1 : value;
        apiRef.current.setPage(newPage);
        // console.log({ event, value });
    };

    return (
        <Pagination
            color="primary"
            count={pageCount}
            page={currentPage}
            siblingCount={2}
            onChange={handleChange}
        />
    );
};

export default CustomGridPagination;
