import React from 'react';

interface IDisplayResponseProps {
    data: any;
}

const DisplayResponse: React.FC<IDisplayResponseProps> = ({ data }) => {
    const renderObject = (obj: any, indentLevel: number = 0): React.ReactNode => {
        const indent = Array(indentLevel + 1).fill('  ').join('');

        if (Object.keys(obj).length === 0) {
            return <>{indent}{'{}'}</>;
        }

        return (
            <>
                {Object.entries(obj).map(([key, value]) => (
                    <div key={key}>
                        {`${indent}${key}: `}
                        {typeof value === 'object' && value !== null ? (
                            <div>{renderObject(value, indentLevel + 1)}</div>
                        ) : (
                            value?.toString()
                        )}
                    </div>
                ))}
            </>
        );
    };

    if (data === null) {
        return <div>null</div>;
    }

    if (typeof data !== 'object' || data === undefined) {
        return <div>{String(data)}</div>;
    }

    // return <div>{renderObject(data)}</div>;
    return (
        <div>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    )
};

export default DisplayResponse;
