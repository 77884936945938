import React, { useEffect, useRef, useState } from 'react';
import FileSelector from '_components/FileSelector/FileSelector';
import './BPUploader.scss';
import { useToast } from '_contexts/ToastContext';
import { CheckinSvc } from '_services/checkin.service';
import CancelIcon from '@mui/icons-material/Cancel';

interface IBPUploaderProps {
    pnr?: string | undefined;
}

const BPUploader: React.FC<IBPUploaderProps> = (props: IBPUploaderProps) => {
    const toast = useToast();
    const checkinSvc = CheckinSvc.getInstance();
    const fileSelectorRef: any = useRef(null);
    const [bpFile, setBPFile] = useState<any>(null);
    const [bpName, setBPName] = useState<any>('');

    useEffect(() => {
        if (props.pnr) setBPName(props.pnr);
    }, [props.pnr])

    const handleFileSelect = (file: File) => {
        setBPFile((prev: any) => file);
    }

    const uploadBP = () => {
        if (!bpFile || !bpName) {
            toast('Please fill/select all mandatory fields', 'error');
            return;
        }
        const formData: FormData = new FormData();
        formData.append('file', bpFile, bpName);
        formData.append('pnr', bpName);
        // clearForm();
        checkinSvc.uploadBP(formData)
            .then((res: any) => {
                if (res?.data) {
                    toast('Successfully uploaded BP', 'success');
                } else {
                    toast('Failed to upload BP. Response status is false.', 'warn');
                }
            })
    }
    const clearForm = () => {
        setBPFile(null);
        setBPName('');
        setTimeout(() => {
            fileSelectorRef?.current?.clearFileData();
        }, 200);
    }

    return (
        <div className='bp-uploader-container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='cust-form-group'>
                        <label>
                            PNR<b className='text-error'>*</b>
                        </label>
                        <input
                            id='bp-name'
                            value={bpName}
                            onChange={(e) => setBPName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='cust-form-group'>
                        <label>
                            BP File<b className='text-error'>*</b>
                        </label>
                        <div className='file-selector'>
                            <FileSelector
                                ref={fileSelectorRef}
                                id={`upload-bp`}
                                label='Select Boarding Pass'
                                acceptType='pdf'
                                onFileSelect={(file: File) => handleFileSelect(file)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='cust-form-group upload-btn'>
                        <button className='button-primary' onClick={clearForm}>
                            Reset Form
                        </button>
                        <button className='button-secondary' onClick={uploadBP}>
                            Upload BP
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BPUploader;
