import { AppConstants } from "_constants/App.constants";
import './SearchComponent.scss';
import { useEffect, useState } from "react";
import { VendorMgmntSvc } from "_services/vendorMgmnt.service";
import { IVendorProfile } from "_models/vendor.interface";
import { Checkbox, Flex, Space, Switch } from "antd";
import FormBuilder from "_pages/FormBuilder/FormBuilder";
import { UtilSvc } from "_services/util.service";
import { useVendorDataContext } from '_contexts/VendorDataContext';

const notificationSearchForm = {
    "fields": [
        {
            "name": "createdAt",
            "label": "Creation Date",
            "fieldType": "DATE",
            "defaultOperator": "EQ",
            "extra": {
                "range": true
            },
            "required": true
        },
        {
            "name": "bookingno",
            "label": "Booking Number",
            "fieldType": "TEXT",
            "defaultOperator": "CONTAINS",
            "extra": null,
            "required": true
        },
        {
            "name": "airline",
            "label": "Airline (autocomplete)",
            "fieldType": "AUTOCOMPLETE",
            "defaultOperator": "IN",
            "extra": {
                "sourcetype": "REMOTE",
                "datatype": "object",
                "valuefield": "name",
                "labelfield": "name",
                "url": ""
            }
        },
        {
            "name": "flightno",
            "label": "Flight Number",
            "fieldType": "TEXT",
            "defaultOperator": "EQ",
            "extra": null
        },
    ]
}
const notificationSearchSegments = [
    { 'name': 'By Creation Date', 'segment': ['createdAt'], isSelected: true },
    { 'name': 'By Booking Number', 'segment': ['bookingno', 'airline', 'flightno'], isSelected: false }
]

const SearchComponent = (props: { advanceSearchCategories?: any, onSearch: any }) => {

    const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState<boolean>(false);
    const [productsInfo, setProducts] = useState<any>(null);
    const [tripSearchFields, setTripSearchFields] = useState<any>(null);
    const [searchFields, setSearchFields] = useState<any>(null);
    const [formInputs, setFormInputs] = useState<any>({});
    const [formInputsMap, setFormInputsMap] = useState<any>({});
    const vendorMgmtSvc = VendorMgmntSvc.getInstance();
    const { vendorProfile } = useVendorDataContext();
    const utilSvc = UtilSvc.getInstance();
    const [isNotificationSearchOn, setNotificationSearchOn] = useState<boolean>(false);
    const [resetFlag, setResetFlag] = useState<boolean>(false);

    const initialiseForm = () => {
        setFormInputs({});
        setFormInputsMap({});
    }

    const _onAdvanceSearchOpen = () => {
        setIsAdvanceSearchOpen(!isAdvanceSearchOpen);
    }

    const getSearchFields = () => {
        vendorMgmtSvc.getSearchFields('TRIP_SEARCH').then((res: any) => {
            setTripSearchFields(res.data.fields);
            setSearchFields(res.data.fields);
        });
    }

    const _onInputFieldChange = (e: any) => {
        let searchReq: any = [];
        if (e.target.value != '') {
            searchReq = [{ "field": "bookingno", "operator": "CONTAINS", "value": e.target.value }];
            if (e.keyCode === 13) props.onSearch(searchReq, 'trip');
        }
    }

    const _onEmitRawFormData = (values: any) => {
        // console.log("_onEmitRawFormData");
        // console.log(values);
        setFormInputsMap(values);
    }

    const _onNotificationSearchClick = () => {
        initialiseForm();
        setResetFlag(true);
        setTimeout(() => {
            setResetFlag(false)
        }, 500);
        let v = !isNotificationSearchOn;
        setNotificationSearchOn(v);
        if (v) {
            setSearchFields(notificationSearchForm.fields);
        } else {
            setSearchFields(tripSearchFields);
        }
    }

    const _convertDate = (v: any) => {
        let val: any;
        if (isNotificationSearchOn) {
            val = v.constructor === Array
                ? v.map((el: any) => utilSvc.formatDate2(el, 'yyyy-mm-dd'))
                : v = utilSvc.formatDate2(v, 'yyyy-mm-dd');
        } else {
            val = v.constructor === Array
                ? v.map((el: any) => utilSvc.convertMomentToEpochSeconds(el))
                : v = utilSvc.convertMomentToEpochSeconds(v);
        }
        return val;
    }

    const _onFormChange = (values: { fieldObj: any, value: any }) => {
        // console.log("_onFormChange");
        // console.log(values);
        if (!values.value || (values.value.constructor === Array && values.value.length === 0)) {
            let temp = { ...formInputs };
            delete temp[values.fieldObj.name];
            setFormInputs(temp);
            return;
        } else {
            if (values.fieldObj.fieldType === 'DATE') {
                values.value = _convertDate(values.value);
            }
            setFormInputs({
                ...formInputs,
                [values.fieldObj.name]: {
                    operator: values.fieldObj.extra?.range ? 'BETWEEN' : values.fieldObj.defaultOperator,
                    value: values.value
                }
            });
        }
    }
    const _onFormSubmit = (btn: string, options: { data: any, segment: any }) => {
        let reqPayload: any = [];
        switch (btn) {
            case 'submit':
                isNotificationSearchOn ? _onNotificationFormSubmit(options.segment) : _onTripFormSubmit();
                break;
            case 'Cancel':
                setIsAdvanceSearchOpen(false);
                setFormInputsMap({});
                props.onSearch(reqPayload, 'trip');
                break;
            case 'Hide':
                setIsAdvanceSearchOpen(false);
                return;
        }
    }
    const _onTripFormSubmit = () => {
        let reqPayload: any = [];
        if (Object.keys(formInputs).length > 0) {
            Object.keys(formInputs).forEach((key: any) => {
                // console.log({ key, val: formInputs[key].value, op: formInputs[key].operator });
                reqPayload.push({ 'field': key, 'operator': formInputs[key].operator, 'value': formInputs[key].value });
            });
        }
        props.onSearch(reqPayload, 'trip');
    }
    const _onNotificationFormSubmit = (segment: any) => {
        let reqPayload: any = [];
        if (segment) {
            notificationSearchSegments.find((el: any) => el.name === segment)?.segment.forEach((key: any) => {
                reqPayload.push({ 'field': key, 'value': key === 'airline' ? formInputs[key]?.value?.code : formInputs[key]?.value });
            });
        }
        props.onSearch(reqPayload, 'notification');
    }


    useEffect(() => {
        if (productsInfo)
            getSearchFields();
    }, [productsInfo]);

    useEffect(() => {
        let products: any = [];
        if (vendorProfile) {
            vendorProfile.productFeatures.forEach((obj: any) => {
                products.push({ label: obj.name, value: obj.id });
            });
            setProducts(products);
        }
    }, [vendorProfile]);

    return (
        <>
            {
                !isAdvanceSearchOpen &&
                <div className="searchWrapper">
                    <div className="searchPretext">
                        <img src={AppConstants.IMAGE_PATH + 'search.png'} alt="search" />
                    </div>
                    <input type="text" className={"searchInput " + (props.advanceSearchCategories ? 'filterOnWidth' : 'fullWidth')} placeholder="Search..." onKeyUp={_onInputFieldChange} />
                    {
                        props.advanceSearchCategories &&
                        <div className="advancedBtn" onClick={_onAdvanceSearchOpen}>
                            <img src={AppConstants.IMAGE_PATH + 'filter.png'} alt="filter" />
                        </div>
                    }
                </div>
            }

            {formInputsMap && Object.keys(formInputsMap).length > 0 && !isAdvanceSearchOpen && <div className="advancedSearchOnMsg">Advance Search is On</div>}

            {/* {isAdvanceSearchOpen &&  */}
            <div className={isAdvanceSearchOpen ? "advanceSearchWrapper " : "advanceSearchWrapper hide"}>
                {/* { productsInfo && <Checkbox.Group options={productsInfo} onChange={_onProductChange} /> } */}
                {/* <img src="" */}
                <Flex justify={'center'} align={'center'} style={{ marginBottom: 30 }}>
                    <Switch size="small" onChange={_onNotificationSearchClick} />
                    <Space style={{ marginLeft: 10 }}>Turn on Notification Search</Space>
                </Flex>

                {searchFields &&
                    <FormBuilder
                        fields={searchFields}
                        data={formInputsMap}
                        segments={isNotificationSearchOn ? notificationSearchSegments : null}
                        layout="horizontal"
                        primaryButtonTitle="Search"
                        extraButtons={['Cancel', 'Hide']}
                        emitFormDataFormat={_onEmitRawFormData}
                        onFormSubmit={_onFormSubmit}
                        onFormChange={_onFormChange}
                        resetFlag={resetFlag}
                    />
                }
            </div>
        </>
    );
}

export default SearchComponent;