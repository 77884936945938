import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/scss/bootstrap.scss';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { LicenseInfo } from '@mui/x-license';

// import { createTheme } from '@mui/material/styles';
// const theme = createTheme();
// document.documentElement.style.setProperty('--success-light-color', theme.palette.success.light);
// document.documentElement.style.setProperty('--error-light-color', theme.palette.error.light);

// LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PK as string);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
