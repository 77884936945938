import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";

export class DMSSvc extends UrlProviderService {
    private static instance: DMSSvc;
    private constructor() {
        super('dms-service-api');
    }
    public static getInstance(): DMSSvc {
        if (!this.instance) {
            this.instance = new DMSSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);

    uploadFile(formData: FormData) {
        const uri = this.generateUrl(`fileupload`);
        return this.apiSvc.post(uri, formData, { setAuth: true });
    }
    downloadFile(dmscode: string) {
        const uri = this.generateUrl(`download/${dmscode}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    getDocumentUrl(dmscode: string) {
        const uri = this.generateUrl(`download/${dmscode}`);
        return `${process.env.REACT_APP_BASE_URL}${uri}`; // check if this is correct
    }
}