import React, { useState } from 'react';
import { ITripSearchData } from '_models/vendor.interface';
import './SimulateComp.scss';
import { useToast } from '_contexts/ToastContext';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
import CancelIcon from '@mui/icons-material/Cancel';
import DisplayResponse from '../../../../_components/DisplayResponse/DisplayResponse';

interface ISimulateTripProps {
    trip: ITripSearchData;
    onCloseModal: () => void;
}

interface ISimRequest {
    touchStatus: string;
    bookingNo: string;
    fromIata: string;
    toIata: string;
    deptGate: string;
    deptTerminal: string;
    arrivalGate: string;
    arrivalTerminal: string;
    flightNo: string;
    airlineIata: string;
    // onDemandFS: boolean;
}
interface IOption {
    value: OptionType
    label: string;
}
type OptionType = 'FG_DELAY' | 'FG_CANCELLED' | '';
const options: IOption[] = [
    { value: '', label: 'Select' },
    { value: 'FG_CANCELLED', label: 'Cancelled' },
    { value: 'FG_DELAY', label: 'Delayed' }
];

const SimulateTrip: React.FC<ISimulateTripProps> = (props: ISimulateTripProps) => {
    const toast = useToast();
    const vendorMgmntSvc = VendorMgmntSvc.getInstance();
    const { trip, onCloseModal } = props;
    const [selectedOption, setSelectedOption] = useState<OptionType>("");
    const [deptGate, setDeptGate] = useState<string>("");
    const [deptTerminal, setDeptTerminal] = useState<string>("");
    const [arrivalGate, setArrivalGate] = useState<string>("");
    const [arrivalTerminal, setArrivalTerminal] = useState<string>("");
    const [delayDuration, setDelayDuration] = useState<string>("");
    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const [onDemandFS, setOnDemandFS] = useState<boolean>(false);
    const [resData, setResData] = useState<any>(false);

    const handleSimTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const op = event.target.value;
        const opTypeArr: OptionType[] = ['FG_DELAY', 'FG_CANCELLED', '']
        setSelectedOption(op as OptionType);
    };
    const handleDelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDelayDuration(event.target.value);
    };

    const simulate = () => {
        setDirtyFormFlag(true);
        if (!checkValidity()) {
            toast(`Please fill/select all mandatory fields correctly.`, 'error');
            return;
        }

        const payload: ISimRequest = {
            touchStatus: selectedOption,
            bookingNo: trip.bookingno,
            fromIata: trip.segment.from.iata,
            toIata: trip.segment.to.iata,
            deptGate: deptGate,
            deptTerminal: deptTerminal,
            arrivalGate: arrivalGate,
            arrivalTerminal: arrivalTerminal,
            flightNo: trip.segment.flightnum,
            airlineIata: trip.segment.airline.iata
        };
        const queryParam: string | null = selectedOption === 'FG_DELAY' ? delayDuration : null;
        setResData(false);
        vendorMgmntSvc.simulate(payload, queryParam, onDemandFS)
            .then((res: any) => {
                // console.log(res);
                if (res?.data) {
                    setResData(res.data);
                    const bNo = payload.bookingNo;
                    const resObj = res.data;
                    if (resObj[bNo] && resObj[bNo].responseCode === 200) {
                        toast('Successfully simulated.', 'success');
                    } else {
                        toast('Failed to simulate.', 'warn');
                    }
                } else {
                    console.warn(res);
                    toast('Failed to simulate. Response status is false.', 'warn');
                }
            });
    }
    const checkValidity = (): boolean => {
        if (!selectedOption) return false;
        if (selectedOption === "FG_DELAY") {
            // if (!deptGate) return false;
            // if (!deptTerminal) return false;
            // if (isNaN(Number(delayDuration))) return false;
            if (!delayDuration) return false;
        }
        return true;
    }

    return (
        <div className='simulate-trip-container'>
            <div className='inner-wrapper form-wrapper'>
                <div className='header-data'>
                    <h3>Simulate Flight</h3>
                    <div>
                        <CancelIcon className="close-modal" onClick={onCloseModal} />
                    </div>
                </div>

                <div className='cust-form-group'>
                    <div>
                        <label>Selected segment details</label>
                    </div>
                    <div className='data-info-wrapper'>
                        <table className='data-info'>
                            <tbody>
                                <tr>
                                    <td>Booking number</td>
                                    <td>{trip.bookingno}</td>
                                </tr>
                                <tr>
                                    <td>Flight</td>
                                    <td>{trip.segment.airline.iata}{trip.segment.flightnum}</td>
                                </tr>
                                <tr>
                                    <td>From</td>
                                    <td>{trip.segment.from.iata}</td>
                                </tr>
                                <tr>
                                    <td>To</td>
                                    <td>{trip.segment.to.iata}</td>
                                </tr>
                                <tr>
                                    <td>Departure</td>
                                    <td>{trip.segment.dept?.deptDateTimeISO?.replaceAll('T', ' ')}</td>
                                </tr>
                                <tr>
                                    <td>Flight</td>
                                    <td>{trip.segment.airline.iata}{trip.segment.flightnum}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='cust-form-group'>
                    <label htmlFor={`sim-type`}>Simulation type<b className='text-error'>*</b></label>
                    <select
                        name='sim-type'
                        id={`sim-type`}
                        value={selectedOption}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleSimTypeChange(e)}
                    >
                        {
                            [...options]
                                .map((op: IOption) => (
                                    <option key={`${op.value}-${op.label}`} value={op.value}>
                                        {op.label}
                                    </option>
                                ))
                        }
                    </select>
                    {(dirtyFormFlag && !selectedOption) && <div className='text-error error-margin'>Please select the simulation type.</div>}
                </div>
                {(!!selectedOption && selectedOption === "FG_DELAY") &&
                    <div className='cust-form-group'>
                        <label htmlFor={`delay-dur`}>Delay duration (in mins)<b className='text-error'>*</b></label>
                        <input
                            name='delay-dur'
                            id={`delay-dur`}
                            type='number'
                            value={delayDuration}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDelayChange(e)}
                        />
                        {
                            (dirtyFormFlag && !delayDuration) &&
                            <div className='text-error error-margin'>Please enter the delay duration.</div>
                        }
                    </div>}

                {(!!selectedOption && selectedOption === "FG_DELAY") &&
                    <>
                        <div className='cust-form-group'>
                            <label htmlFor={`deptTerminal`}>Departure Terminal</label>
                            <input
                                name='deptTerminal'
                                id={`deptTerminal`}
                                value={deptTerminal}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeptTerminal(e.target.value)}
                            />
                            {/* {
                                (dirtyFormFlag && !deptTerminal) &&
                                <div className='text-error error-margin'>Please enter the departure terminal.</div>
                            } */}
                        </div>
                        <div className='cust-form-group'>
                            <label htmlFor={`deptGate`}>Departure Gate</label>
                            <input
                                name='deptGate'
                                id={`deptGate`}
                                value={deptGate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeptGate(e.target.value)}
                            />
                            {/* {
                                (dirtyFormFlag && !deptGate) &&
                                <div className='text-error error-margin'>Please enter the departure gate.</div>
                            } */}
                        </div>
                        <div className='cust-form-group'>
                            <label htmlFor={`arrivalTerminal`}>Arrival Terminal</label>
                            <input
                                name='arrivalTerminal'
                                id={`arrivalTerminal`}
                                value={arrivalTerminal}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setArrivalTerminal(e.target.value)}
                            />
                        </div>
                        <div className='cust-form-group'>
                            <label htmlFor={`arrivalGate`}>Arrival Gate</label>
                            <input
                                name='arrivalGate'
                                id={`arrivalGate`}
                                value={arrivalGate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setArrivalGate(e.target.value)}
                            />
                        </div>
                    </>}

                {!!selectedOption &&
                    <div className='cust-form-group'>

                        <div className='checkbox-wrapper'>
                            <input
                                name='onDemandStatus'
                                id={`onDemandStatus`}
                                type='checkbox'
                                checked={onDemandFS}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOnDemandFS(e.target.checked)}
                            />
                            <label htmlFor={`onDemandStatus`}>On Demand Status</label>
                        </div>
                    </div>}

                <div className='cust-form-group' style={{ marginTop: '20px' }}>
                    <div className='btn-grp-wrapper'>
                        <button className='button-primary' onClick={onCloseModal}>
                            Close
                        </button>
                        <button className='button-primary' onClick={simulate}>
                            Simulate
                        </button>
                    </div>
                </div>
                {
                    resData !== false &&
                    <div className='cust-form-group' style={{ marginTop: '20px' }}>
                        <div>
                            <label>Response</label>
                        </div>
                        <div className="data-info-wrapper">
                            <DisplayResponse data={resData} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default SimulateTrip;