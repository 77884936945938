import React, { useState, useEffect } from 'react';
import { ITripResponse, ITripResData, ITripResSegment, ITripResRowData } from '_models/vendor.interface';
import { DataGrid, GridToolbar, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';
import { getErrorReason, getAviationCode, getFlightTime, renderStatus, renderFlightTimeHeader } from '_modules/VendorModule/_components/Widget/Widget';
import { MUIXGridSvc } from '_services/muixGrid.service';

interface ITripCreationResponseProps {
    tripResponse: ITripResponse;
}

const resColData: GridColDef[] = [
    { field: 'isSuccess', headerName: 'Status', width: 75, renderCell: renderStatus },
    { field: 'bookingno', headerName: 'Booking Number', width: 150 },
    { field: 'segId', headerName: 'Segment Id', width: 150 },
    { field: 'errors', headerName: 'Error Reason', width: 150, valueGetter: getErrorReason },
    { field: 'from', headerName: 'From IATA Code', width: 100 },
    { field: 'to', headerName: 'To IATA Code', width: 100 }
];

const TripCreationResponse: React.FC<ITripCreationResponseProps> = (props: ITripCreationResponseProps) => {
    const { tripResponse } = props;
    const [resRowData, setResRowData] = useState<ITripResRowData[]>([]);
    const muixSvc = MUIXGridSvc.getInstance();

    // const gridRef = useGridApiRef();
    // const initialGridState = useKeepGroupedColumnsHidden({
    //     apiRef: gridRef,
    //     initialState: {
    //         rowGrouping: {
    //             model: ['bookingno'],
    //         },
    //     },
    // });

    const initialGridState = {
        rowGrouping: {
            model: ['bookingno'],
        },
    }

    useEffect(() => {
        if (tripResponse) {
            const data: ITripResRowData[] = [];
            tripResponse.invalid_segments?.forEach((seg: ITripResData) => {
                seg?.invalidSegments?.forEach((segData: ITripResSegment) => {
                    data.push({
                        id: `${seg.bookingno}-${segData.segId}`,
                        isSuccess: false,
                        bookingno: seg.bookingno,
                        ...segData
                    });
                });
            });
            // console.log(data);
            setResRowData(data);
            setTimeout(() => muixSvc.hideLicenseDiv(), 10);
        }
    }, [tripResponse])

    return (
        <div className='trip-response-container'>
            {
                !!resRowData.length ?
                    <>
                        <p>
                            <span className='text-danger'><FaTimesCircle /></span> Failed to created trip for {resRowData.length} segment
                        </p>
                        <div className='data-grid data-grid-large'>
                            <DataGridPremium
                                className=''
                                rows={resRowData}
                                columns={resColData}
                                density='compact'
                                slots={{ toolbar: GridToolbar }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                // disableRowGrouping
                                // apiRef={gridRef}
                                // initialState={initialGridState}
                                initialState={initialGridState}
                                defaultGroupingExpansionDepth={-1}
                            />
                        </div>
                    </>
                    :
                    <p>
                        <span className='text-success'><FaCheckCircle /></span> Trip creation successfull
                    </p>
            }
        </div>
    );
};

export default TripCreationResponse;