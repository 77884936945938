import './FileSelector.scss';
import { useState, ChangeEvent, DragEvent, useImperativeHandle, forwardRef, useRef } from 'react';
import { useToast } from '_contexts/ToastContext';
import DisplayResponse from '_components/DisplayResponse/DisplayResponse';

interface IFileSelectorProps {
    id: string;
    label: string;
    acceptType: string;
    onFileSelect: (file: File) => void;
}

const FileSelector = forwardRef(({ id, label, acceptType, onFileSelect }: IFileSelectorProps, ref) => {
    const [file, setFile] = useState<File | null>(null);
    const [imageSrc, setImageSrc] = useState(null);
    const toast = useToast();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragOver = (event: DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    };
    const handleDrop = (event: DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        handleFileChange(event);
    };
    useImperativeHandle(ref, () => ({
        clearFileData() {
            setFile(null);
            setImageSrc(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return true;
        }
    }));
    const handleFileChange = (event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLSpanElement>) => {
        let selectedFile = null;
        if ('dataTransfer' in event) {
            event.preventDefault();
            event.stopPropagation();
            const selectedTempFile = event.dataTransfer.files?.[0];
            // console.log(selectedTempFile.type);
            if (!checkFileType(selectedTempFile)) {
                toast('Selected file type is not allowed', 'error')
                return;
            }
            selectedFile = selectedTempFile;
        } else {
            selectedFile = event.target.files?.[0];
        }
        if (selectedFile) {
            // console.log(selectedFile.type);
            setFile(selectedFile);
            onFileSelect(selectedFile);
            if (selectedFile.type.startsWith('image')) {
                const reader = new FileReader();
                reader.onload = (e: any) => { setImageSrc(e.target?.result); };
                reader.readAsDataURL(selectedFile);
            } else {
                setImageSrc(null);
            }
        }
    };
    // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const selectedFile = e.target.files?.[0];
    //     if (selectedFile) {
    //         setFile(selectedFile);
    //         onFileSelect(selectedFile);
    //     }
    // }
    const checkFileType = (selectedfile: File): boolean => {
        let isValid: boolean = false;
        if (!selectedfile) return isValid;
        if (acceptType.includes(selectedfile.type)) isValid = true;
        if (acceptType.startsWith('image') && (selectedfile.type).startsWith('image')) isValid = true;
        return isValid;
    }

    return (
        <div className='file-selector-container'>
            <div className='file-selector-wrapper'>
                <span className='select-file-btn' onDrop={handleDrop} onDragOver={handleDragOver}>
                    <label htmlFor={`select-file-${id}`}>
                        <span className='button-primary'>
                            {label}
                        </span>
                    </label>
                    <input
                        ref={fileInputRef}
                        id={`select-file-${id}`}
                        type='file'
                        accept={acceptType}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
                    />
                </span>
                <div className='filename'>
                    {file ?
                        <span>
                            {file?.name}
                        </span>
                        :
                        <span>
                            {
                                !['image/*'].includes(acceptType) &&
                                'No file has been selected'
                            }
                        </span>
                    }
                </div>
            </div>
            {!!imageSrc &&
                <div className='img-wrapper'>
                    <img src={imageSrc as string} alt='' />
                </div>
            }
        </div>
    )
});

export default FileSelector;