import "./CustomModal.scss";
import { Box, Grid, Modal } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

interface ICustomModalProps {
    children: any;
    headerName?: string;
    modalClassName?: string;
    maxWidth?: 'sm' | 'md' | 'lg';
    maxWidthPixel?: number;
    openModalFlag: boolean;
    onCloseModal: () => void;
}

const CustomModal: React.FC<ICustomModalProps> = (props: ICustomModalProps) => {
    const { children, headerName = '', modalClassName = '', maxWidth = 'lg', maxWidthPixel, openModalFlag, onCloseModal } = props;

    return (
        <>
            <Modal
                open={openModalFlag}
                onClose={onCloseModal}
            >
                <Grid
                    container
                    maxWidth={maxWidthPixel ? maxWidthPixel + 'px' : maxWidth}
                    className={`custom-modal-container ${modalClassName}`}
                >
                    <Grid item xs={12} className="header-div">
                        <h3>{headerName}</h3>
                        <div>
                            <CancelIcon className="close-modal" onClick={onCloseModal} />
                        </div>
                    </Grid>
                    <Grid item xs={12} className="content-div">
                        {children}
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};

export default CustomModal;
