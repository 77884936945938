import '_modules/VendorModule/_styles/Vendor.scss';
import './FlightWatcherSearch.scss';
import { useState, useRef, useCallback, useEffect, ReactNode } from 'react';
import { useToast } from '_contexts/ToastContext';
import { ITripSearch, ITripSearchData, IPassenger, ISearchDataProductFeatures } from '_models/vendor.interface';
import { MUIXGridSvc } from '_services/muixGrid.service';
import { DataGrid, GridColDef, GridRowParams, GridPaginationModel, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams, GridToolbar } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { CheckinSvc } from '_services/checkin.service';
import { IBPViewerFlag, ICheckinData, IPNRSearch } from '_models/checkin.interface';
import { Grid, Modal } from '@mui/material';
import SimulateBp from '_modules/VendorModule/_components/SimulateComp/SimulateBp';
import TripBpViewer from '_modules/VendorModule/_components/TripBpViewer/TripBpViewer';

interface IDetailPanelProps {
    tData: ITripSearchData;
    showSim: boolean;
}
const DetailPanelContent: React.FC<IDetailPanelProps> = (props: IDetailPanelProps) => {
    // console.log(props.data);
    const toast = useToast();

    const checkinSvc = CheckinSvc.getInstance();
    const muixGridSvc = MUIXGridSvc.getInstance();

    const [tripData, setTripData] = useState<ITripSearchData>();
    const [simRes, setSimRes] = useState<any>();
    const [bpViewerFlag, setBPViewerFlag] = useState<boolean>(false);
    const [passengerInfo, setPassengerInfo] = useState<IPassenger>();
    const [allDisposed, setAllDisposed] = useState<boolean>(false);

    const renderSendBPBtn = (params: any): ReactNode => {
        const data = (params.row as IPassenger);

        if (!tripData || !checkIfCheckin(tripData)) return <></>;

        const dmscode = data?.chkData?.pass?.dmscode;
        const classData = 'cust-grid-btn ' + (dmscode ? 'cust-btn-success' : 'cust-button-secondary');
        const displayLabel = (dmscode ? 'Send BP' : 'Send BP')
        return (
            <button
                className={classData}
                onClick={() => sendBP(data)}
                disabled={!dmscode}
            >
                {displayLabel}
            </button >
        )
    }
    const sendBP = (data: IPassenger) => {
        if (!data || !data.chkData?.pnr || !data.chkData?._id || !props.tData.bookingno) {
            toast('PNR, Booking Number and/or Id is missing', 'error');
            return;
        }
        checkinSvc.sendBP(data.chkData.pnr, data.chkData._id.toString(), props.tData.bookingno, allDisposed)
            .then(res => {
                if (res?.data) {
                    setSimRes(res.data);
                    const pnrData = data.chkData?.pnr;
                    const resObj = res.data;
                    if (pnrData && resObj?.[pnrData] && resObj[pnrData].responseCode === 200) {
                        toast('Successfully sent boarding pass', 'success');
                    } else {
                        toast('Failed to send boarding pass.', 'warn');
                    }
                } else {
                    toast('Failed to send boarding pass', 'warn');
                    setSimRes(null);
                }
            })
    }
    const renderOpenBPBtn = (params: any): ReactNode => {
        const data = (params.row as IPassenger);
        const dmsData = data?.chkData?.pass?.dmscode;

        if (!tripData || !checkIfCheckin(tripData)) return <></>;

        const classData = 'cust-grid-btn ' + (dmsData ? 'cust-btn-success' : 'cust-button-secondary');
        return (
            <button
                className={classData}
                onClick={() => openBP(data)}
                disabled={!dmsData}
            >
                Open BP
            </button >
        )
    }
    const openBP = (data: IPassenger) => {
        if (data) {
            setBPViewerFlag(true);
            setPassengerInfo(data);
        }
    }
    const isBPAttached = (params: any): boolean => {
        const data: ICheckinData | undefined = (params.row as IPassenger).chkData;
        if (data?.pass?.dmscode) return true
        return false;
    }
    const checkinClassName: string = 'checkin-data';
    const passengerColData: GridColDef[] = [
        { field: 'firstname', headerName: 'First Name', width: 150 },
        // { field: 'middlename', headerName: 'Middle Name', width: 150 },
        { field: 'lastname', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 150 },
        { field: 'chkDatadata?.pass?.dmscode', headerName: 'Is BP Attached', width: 150, type: 'boolean', valueGetter: (params) => isBPAttached(params), cellClassName: checkinClassName },
        // { field: 'dmscode', headerName: 'DMS', width: 150, valueGetter: (params) => (params.row as IPassenger).chkData?.pass?.dmscode, cellClassName: checkinClassName },
        { field: 'chkDatadata?.pass?.page', headerName: 'Open BP', width: 150, renderCell: renderOpenBPBtn, cellClassName: checkinClassName },
        ...(props.showSim ? [{ field: 'chkDatadata?.pass', headerName: 'Send BP', width: 150, renderCell: renderSendBPBtn, cellClassName: checkinClassName },] : []),
        // { field: 'chkDatadata?.pass', headerName: 'Send BP', width: 150, renderCell: renderSendBPBtn, cellClassName: checkinClassName },
        { field: 'phoneCode', headerName: 'Phone Code', width: 100 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 100 },
        { field: 'dob', headerName: 'Date of Birth', width: 100 },
        { field: 'gender', headerName: 'Gender', width: 100 },
        { field: 'primary', headerName: 'Is Primary', width: 100, type: 'boolean' },
        // { field: 'pnr', headerName: 'PNR', width: 100 },
        { field: 'eticket', headerName: 'E-Ticket', width: 100 },
        // { field: 'freqFlyerNo', headerName: 'Frequent Flyer Number', width: 100 },
        // { field: 'address.line1', headerName: 'Address Line 1', width: 150 },
        // { field: 'address.line2', headerName: 'Address Line 2', width: 150 },
        // { field: 'address.city', headerName: 'City', width: 150 },
        // { field: 'address.state', headerName: 'State', width: 150 },
        // { field: 'address.country', headerName: 'Country', width: 150 },
        // { field: 'address.pincode', headerName: 'Pincode', width: 100 }
    ];
    const passengerColDataNoCheckin: GridColDef[] = passengerColData.filter((o: GridColDef) => {
        if (!o.cellClassName) return true;
        if (o.cellClassName === checkinClassName) return false;
        return true;
    });

    useEffect(() => {
        if (checkIfCheckin(props.tData)) _searchCheckins(props.tData, false);
        else setTripData(props.tData);
    }, [props.tData])

    useEffect(() => {
        setTimeout(muixGridSvc.hideLicenseDiv, 100);
    }, [tripData])

    const checkIfCheckin = (trip: ITripSearchData) => {
        const pdtFeatures: ISearchDataProductFeatures[] = trip.productWithFeatures;
        const checkinPdtFeature: ISearchDataProductFeatures = { product: 4, feature: 1 };
        if (pdtFeatures?.some(item => item.product === checkinPdtFeature.product)) return true;
        return false;
    }

    const _searchCheckins = (trip: ITripSearchData, allDisposedVal: boolean) => {
        searchCheckins(trip, allDisposedVal, 0);
    }
    const searchCheckins = (trip: ITripSearchData, allDisposedVal: boolean, counter: number) => {
        counter++;
        checkinSvc.searchCheckinsByPNR(trip.pnr, true, allDisposedVal)
            .then((res: any) => {
                if (res?.data) {
                    if (res.data?.length === 0) {
                        if (counter < 2) {
                            // console.log('recursive call searchCheckins, counter =', counter);
                            searchCheckins(trip, !allDisposedVal, counter);
                        } else {
                            // console.log('setTripData, counter =', counter);
                            setTripData(trip);
                        }
                    }
                    else {
                        const searchDataArr: IPNRSearch[] = res.data;
                        const searchData: IPNRSearch | undefined = searchDataArr.find((data: IPNRSearch) => data.bookingNo === trip.bookingno);
                        if (searchData) {
                            setAllDisposed(searchData.allDisposed);
                            trip.travellers?.forEach((trv: IPassenger) => {
                                const checkinData: ICheckinData | undefined = searchData.checkins?.find((chk: ICheckinData) => {
                                    if (
                                        chk.from.iata === trip.segment.from.iata &&
                                        chk.to.iata === trip.segment.to.iata &&
                                        chk.airline.iata === trip.segment.airline.iata &&
                                        chk.flightnum === trip.segment.flightnum &&
                                        chk.passenger.firstname === trv.firstname &&
                                        chk.passenger.lastname === trv.lastname
                                    ) return true;
                                    return false;
                                });
                                trv.chkData = checkinData;
                            });
                            setTripData(trip);
                        }
                    }
                }
            })
            .catch((err: any) => {
                if (counter < 2) searchCheckins(trip, !allDisposedVal, counter);
            })
    }

    tripData?.travellers.forEach((traveller: IPassenger, index: number) => {
        traveller.id = `${tripData.id}-${index}`;
    });
    // data.segment.id = data.segment.segmentid;

    const handleCloseSimulateModal = () => {
        setSimRes(null);
    }
    const handleUpdateDisplayFlag = (display: boolean) => {
        setBPViewerFlag(display);
    }

    return (
        <div className='detail-panel-container'>
            {
                <Modal
                    open={!!simRes}
                    onClose={() => handleCloseSimulateModal()}
                >
                    <Grid
                        container
                        maxWidth={'sm'}
                        margin='0px auto'
                        className={"custom-modal-grid"}
                    >
                        <SimulateBp
                            response={simRes}
                            onCloseModal={() => handleCloseSimulateModal()}
                        />
                    </Grid>
                </Modal>
            }
            {
                bpViewerFlag &&
                <TripBpViewer
                    displayFlag={bpViewerFlag}
                    passengerInfo={passengerInfo}
                    onUpdateDisplayFlag={(display: boolean) => handleUpdateDisplayFlag(display)}
                />
            }
            {
                !!tripData ?
                    <div className='data'>
                        <div className='text-content'>
                            Traveller data {tripData.pnr && `for PNR ${tripData.pnr}`}
                        </div>
                        {/* <div>
                            {tripData.travellers?.map((traveller: IPassenger, index: number) => (<div key={index} style={{ marginTop: '5px' }}>
                                <span>{JSON.stringify(traveller.chkData, null, 2)}</span>
                                {!traveller.chkData && <span>No checkin data</span>}
                            </div>))}
                        </div> */}
                        <div className='grid-content data-grid'>
                            <DataGridPremium
                                density="compact"
                                columns={checkIfCheckin(tripData) ? passengerColData : passengerColDataNoCheckin}
                                rows={tripData.travellers}
                                hideFooter
                                // autoHeight
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                            />
                        </div>
                    </div>
                    :
                    <div className='no-data'>
                        <span>No data</span>
                    </div>
            }
        </div>
    );
}

export default DetailPanelContent;