

import { DataGrid, GridRowParams } from '@mui/x-data-grid'; import { darken, lighten, styled } from '@mui/material/styles';

export class MUIXGridSvc {
    private static instance: MUIXGridSvc;
    public static getInstance(): MUIXGridSvc {
        if (!this.instance) {
            this.instance = new MUIXGridSvc();
        }
        return this.instance;
    }

    // hide license message
    hideLicenseDiv = () => {
        const divs = document.querySelectorAll('div');
        divs.forEach(div => {
            if (div.innerText.includes('MUI X Missing') && div.children.length === 0) {
                div.style.display = 'none';
                // console.log('Found the div:', div);
            }
        });
    }
    // hideLicenseDiv = () => {
    //     const divs = Array.from(document.querySelectorAll('.data-grid div'));
    //     divs.forEach((div: Element) => {
    //         if (this.hasNoNestedDivs(div) && div.textContent?.includes('MUI X Missing license key')) {
    //             (div as HTMLDivElement).style.display = 'none';
    //         }
    //     });
    // }
    // hasNoNestedDivs = (element: Element) => {
    //     return element.querySelectorAll('div').length === 0;
    // }

    // styled grid
    getRowClassName = (params: GridRowParams) => {
        const successValue = params.row.success;
        if (successValue !== undefined) {
            return successValue ? 'success-row' : 'error-row';
        }
        return '';
    };
    getBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
    getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
    getSelectedBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
    getSelectedHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
    StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .success-row': {
            backgroundColor: this.getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: this.getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: this.getSelectedBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: this.getSelectedHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .error-row': {
            backgroundColor: this.getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: this.getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: this.getSelectedBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: this.getSelectedHoverBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode,
                    ),
                },
            },
        }
    }));
}