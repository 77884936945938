
import '_modules/VendorModule/_styles/Vendor.scss';
import { useState, useCallback, useEffect } from 'react';
import { useToast } from '_contexts/ToastContext';
import { ITripSearch, ITripSearchData, IProductFeatures } from '_models/vendor.interface';
import { VendorMgmntSvc } from "_services/vendorMgmnt.service";
import { MUIXGridSvc } from '_services/muixGrid.service';
import { DataGridPremium, GridColDef, GridRowParams, GridPaginationModel, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridValueGetterParams, GridToolbar, GridRowId, GridToolbarExport } from '@mui/x-data-grid-premium';
import { FaTrash } from "react-icons/fa";
import { FaWindowRestore } from "react-icons/fa6";
import { getTripActiveStatus, getProductFeatureTypes, getTravelersCount, getTime, getSegmentData, getTime2 } from "_modules/VendorModule/_components/Widget/Widget";
import SearchComponent from '_components/Search/SearchComponent';
import { Grid, Modal } from '@mui/material';
import SimulateTrip from '_modules/VendorModule/_components/SimulateComp/SimulateTrip';
import { CommSenderSvc } from '_services/commSender.service';
import { INotificationData, INotificationSearch } from '_models/notification.interface';
import DetailPanelContent from './DetailPanelContent';
import BPUploader from '_modules/CheckinOperatorModule/_components/BPUploader/BPUploader';
import CustomModal from '_components/CustomModal/CustomModal';
import { FaDownload } from "react-icons/fa";
import CustomGridPagination from '_components/CustomGridPagination/CustomGridPagination';
import SearchComponent2 from '_components/Search/SearchComponent2';

type SearchType = 'trip' | 'notification';
interface ISearchDetails {
    status: boolean | null;
    text: string;
    type: SearchType;
}

const FlightWatcherSearch = () => {
    const showSim: boolean = process.env.REACT_APP_SHOW_SIM === 'true';

    const vendorMgmntSvc = VendorMgmntSvc.getInstance();
    const commSenderSvc = CommSenderSvc.getInstance();
    const muixGridSvc = MUIXGridSvc.getInstance();
    const toast = useToast();

    const [searchTripRowData, setTripSearchRowData] = useState<ITripSearchData[]>([]);
    const [searchNotificationRowData, setNotificationSearchRowData] = useState<INotificationData[]>([]);
    const [searchDetails, setSearchDetails] = useState<ISearchDetails>({ status: null, text: '', type: 'trip' });
    const [searchReqBody, setSearchReqBody] = useState<any>(null);

    const [rowCountState, setRowCountState] = useState<number>(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 50 });
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedRow, setSelectedRow] = useState<ITripSearchData>();
    const [selectedPnr, setSelectedPnr] = useState<string>('');

    const [simModalFlag, setSimModalFlag] = useState<boolean>(false);
    const [uploadModalFlag, setUploadModalFlag] = useState<boolean>(false);
    const [productFeatures, setProductFeatures] = useState<IProductFeatures[]>([]);

    const searchTripColData: GridColDef[] = [
        // { field: 'createdBy', headerName: 'Created By', width: 150 },
        // { field: 'active', headerName: 'Is Active', width: 100, type: 'boolean', valueGetter: (params) => getSegmentData(params, 'fwatchActivationStatus', 'active'), renderCell: renderStatus },
        // { field: 'deactivatedAt', headerName: 'Deactivated At (UTC)', width: 150, valueGetter: (params) => getSegmentData(params, 'fwatchActivationStatus', 'deactivatedAt') },
        { field: 'bookingno', headerName: 'Booking Number', width: 150 },
        { field: 'pnr', headerName: 'PNR', width: 100 },
        { field: 'createdAt', headerName: 'Created At (UTC)', width: 150, valueGetter: getTime },
        { field: 'dept', headerName: 'Departure (Local)', width: 150 },
        { field: 'airline', headerName: 'Airline', width: 100 },
        { field: 'flightnum', headerName: 'Flight Number', width: 100, valueGetter: (params) => getSegmentData(params, 'flightnum') },
        { field: 'from', headerName: 'From Airport', width: 100 },
        { field: 'to', headerName: 'To Airport', width: 100 },
        { field: 'landed', headerName: 'Has Landed', width: 100, type: 'boolean' },
        { field: 'cancelled', headerName: 'Is Canceled', width: 100, type: 'boolean' },
        { field: 'travellers', headerName: 'Travelers Count', width: 100, valueGetter: getTravelersCount },
        { field: '', headerName: 'Is Active', width: 100, type: 'boolean', valueGetter: getTripActiveStatus },
        { field: 'productWithFeatures', headerName: 'Product Feature Types', width: 100, valueGetter: (params: GridValueGetterParams<string[]>) => getProductFeatureTypes(params, productFeatures) },
        { field: 'eligibleForWatch', headerName: 'Is Eligible for Trips', width: 100, type: 'boolean' },
        { field: 'arrival', headerName: 'Arrival (Local)', width: 150 },
        // { field: 'langCode', headerName: 'Language Code', width: 100 },
        // { field: 'supportCost', headerName: 'Support Cost', width: 100 },
        // { field: 'id', headerName: 'Id', width: 150 },
        // { field: 'segmentid', headerName: 'Segment Id', width: 150 }
    ];
    const searchNotificationColData: GridColDef[] = [
        { field: 'sentTime', headerName: 'Sent On (UTC)', width: 150, valueGetter: (params: GridValueGetterParams<any>) => getTime2(params) },
        { field: 'flight', headerName: 'Flight', width: 100 },
        { field: 'bookingNo', headerName: 'Booking Number', width: 100 },
        { field: 'fromAirport', headerName: 'Origin Airport', width: 100 },
        { field: 'toAirport', headerName: 'Destination Airport', width: 100 },
        { field: 'deptTerminal', headerName: 'Departure Terminal', width: 100 },
        { field: 'deptGate', headerName: 'Departure Gate', width: 100 },
        { field: 'arrivalTerminal', headerName: 'Arrival Terminal', width: 100 },
        { field: 'arrivalGate', headerName: 'Arrival Gate', width: 100 },
        { field: 'flightTouchStatus', headerName: 'Flight Status', width: 150 },
        { field: 'sentToEmail', headerName: 'Sent to Email', width: 150 },
        { field: 'mailSubject', headerName: 'Subject', width: 150 },
        { field: 'sentToPhoneNo', headerName: 'To Phone Number', width: 150 },
        { field: 'smsBody', headerName: 'SMS Body', width: 150 },
        { field: 'whatsappBody', headerName: 'WhatsApp Body', width: 150 },
        { field: 'sentToURL', headerName: 'Sent to URL', width: 150 },
    ];

    useEffect(() => {
        getProductFeaturesAndSearch()
    }, []);

    const getProductFeaturesAndSearch = async () => {
        _getProductFeatures();
    }
    const _getProductFeatures = async () => {
        let d = await vendorMgmntSvc.fetchProductsFeaturesInfo();
        setProductFeatures(d);
        // **** **** **** **** //
        // { "field": "createdAt", "operator": "BETWEEN", "value": [Date.now() / 1000 - 7 * 24 * 60 * 60, Date.now() / 1000] }
        _onTripSearch([]);
    }

    const _onTripSearchFromGrid = (searchPage: GridPaginationModel) => {
        setPaginationModel(searchPage);
        _onTripSearch(searchReqBody, searchPage);
    }
    const _onNotificationSearchFromGrid = (searchPage: GridPaginationModel) => {
        setPaginationModel(searchPage);
        _onNotificationSearch(searchReqBody, searchPage);
    }
    const _onSearch = (searchReq: any, searchType: SearchType) => {
        setSearchDetails((prev: ISearchDetails) => ({ ...prev, type: searchType }));
        switch (searchType) {
            case 'trip':
                _onTripSearch(searchReq);
                break;
            case 'notification':
                _onNotificationSearch(searchReq);
                break;
        }
    }
    const _onNotificationSearch = (searchReq: any, searchPage?: GridPaginationModel) => {
        let page: GridPaginationModel = { ...paginationModel };
        setSearchReqBody(searchReq);
        if (!searchPage) {
            page.page = 0;
            setPaginationModel(page);
        } else {
            page = searchPage;
        }
        let searchText: string = `Notification Search Results`;

        let createdAt = searchReq.find((el: any) => el.field === 'createdAt');
        let bookingNum = searchReq.find((el: any) => el.field === 'bookingno');
        let airline = searchReq.find((el: any) => el.field === 'airline');
        let flightNum = searchReq.find((el: any) => el.field === 'flightno');

        if (createdAt) {
            commSenderSvc.searchByDateRange(createdAt.value[0], createdAt.value[1], page).then((res: any) => {
                if (res?.data) {
                    handleNotificationSearchResponse(res.data, searchText);
                } else {
                    toast('Failed to search notifications. Response status is false.', 'warn');
                    handleSearchError(searchText);
                }
            });
        } else {
            if (bookingNum) {
                commSenderSvc.searchByBookingNumAndFlight(bookingNum.value, airline?.value, flightNum?.value, page).then((res: any) => {
                    if (res?.data) {
                        handleNotificationSearchResponse(res.data, searchText);
                    } else {
                        toast('Failed to search notifications. Response status is false.', 'warn');
                        handleSearchError(searchText);
                    }
                });
            }
        }
    }
    const _onTripSearch = (searchReq: any, searchPage?: GridPaginationModel) => {
        let page: GridPaginationModel = { ...paginationModel };
        setSearchReqBody(searchReq);
        if (!searchPage) {
            page.page = 0;
            setPaginationModel(page);
        } else {
            page = searchPage;
        }
        let searchText: string = `Trip Search Results`;

        if (searchReq) {
            vendorMgmntSvc.searchTrip(searchReq, page).then((res: any) => {
                if (res?.data?.content)
                    handleTripSearchResponse(res.data, searchText);
                else {
                    toast('Failed to search trips. Response status is false.', 'warn');
                    handleSearchError(searchText);
                }
            });
        }
    }

    const handleNotificationSearchResponse = (searchData: INotificationSearch, searchText: string) => {
        const content = searchData.content.map((data: INotificationData, index: number) => ({
            ...data,
            id: data.sentTimeEpochSec + index + data.communicationMetadata.bookingNo + data.sentToEmail,
            ...data.communicationMetadata,
            flight: data.communicationMetadata?.airline?.iata + data.communicationMetadata?.flightNo,
            fromAirport: data.communicationMetadata?.fromAirport?.iata,
            toAirport: data.communicationMetadata?.toAirport?.iata
        }));
        setNotificationSearchRowData(content);
        if (searchData.totalElements !== undefined) setRowCountState(searchData.totalElements);
        // console.log(searchDetails, searchText);
        setSearchDetails((prev: ISearchDetails) => ({ ...prev, status: true, text: searchText }));
        setTimeout(muixGridSvc.hideLicenseDiv);
    }
    const handleTripSearchResponse = (searchData: ITripSearch, searchText: string) => {
        const content = searchData?.content?.map((data: ITripSearchData, index: number) => ({
            ...data,
            id: data.id,
            ...data.segment,
            from: data.segment?.from?.iata,
            to: data.segment?.to?.iata,
            airline: data.segment?.airline?.iata,
            // ${data.segment.dept.tz}
            dept: data.segment?.dept ? `${data.segment.dept.date} ${data.segment.dept.time}` : '',
            // ${data.segment.arrival.tz}
            arrival: data.segment?.arrival ? `${data.segment.arrival.date} ${data.segment.arrival.time}` : '',
            segmentid: data.segment?.segmentid
        }));
        setTripSearchRowData(content);
        setRowCountState(searchData?.totalElement);
        setSearchDetails((prev: ISearchDetails) => ({ ...prev, status: true, text: searchText }));
        setTimeout(muixGridSvc.hideLicenseDiv);
    }
    const handleSearchError = (searchText: string) => {
        setSearchDetails((prev: ISearchDetails) => ({ ...prev, status: false, text: searchText }));
        setTimeout(muixGridSvc.hideLicenseDiv);
    }

    const handleCloseSimulateModal = () => {
        setSimModalFlag(false);
        _onTripSearch(searchReqBody, paginationModel);
    }
    const handleCloseUploadBPModal = () => {
        setUploadModalFlag(false);
        _onTripSearch(searchReqBody, paginationModel);
    }
    const deactivateTrip = () => {
        if (rowSelectionModel.length > 1) {
            toast('To deactivate, please select only one row at a time.', 'error');
            return;
        }
        const result = window.confirm('Are you sure you want to deactivate the selected row?');
        if (!result) return;
        const selectedRowsData: (ITripSearchData | undefined)[] = rowSelectionModel.map((selectedId: any) => {
            return searchTripRowData.find((row: ITripSearchData) => row.id === selectedId);
        });
        if (!selectedRowsData[0]) return;

        const bookingnum: string = selectedRowsData[0].bookingno;
        let apiArray: any[] = [];
        selectedRowsData[0].productWithFeatures.forEach((p: any) => {
            const productCode: string | undefined = productFeatures.find((pf: IProductFeatures) => pf.bitpos === p.product)?.code;
            const productName: string | undefined = productFeatures.find((pf: IProductFeatures) => pf.bitpos === p.product)?.name;
            let urlObj: any = { bookingnum: bookingnum, productCode: productCode, productName: productName };
            apiArray.push(urlObj);
        });

        const promises: any = [];

        if (apiArray.length === 0) {
            toast(`No product linked to ${bookingnum}.`, 'warn');
            return;
        }

        apiArray.forEach((obj: any) => {
            promises.push(vendorMgmntSvc.deactivateTrip(obj.bookingnum, obj.productCode));
        });

        Promise.all(promises).then((res: any) => {
            if (res.length > 0) {
                toast(`Deactivated subscription ${bookingnum} for all products successfully.`, 'success');
                _onTripSearch(searchReqBody, paginationModel);
            } else {
                console.warn(res);
                toast(`Failed to deactivate subscription ${bookingnum}. Response status is false.`, 'warn');
            }
        })

    }
    const simulateTrip = () => {
        if (rowSelectionModel.length > 1) {
            toast('To simulate, please select only one row at a time.', 'error');
            return;
        }

        const selectedRowsData: (ITripSearchData | undefined)[] = rowSelectionModel.map((selectedId: any) => {
            return searchTripRowData.find((row: ITripSearchData) => row.id === selectedId);
        });
        if (!selectedRowsData[0]) return;

        setSelectedRow(selectedRowsData[0]);
        setSimModalFlag(true);
    }
    const downloadSearch = () => {
        console.log(searchReqBody);
    }

    const getDetailPanelContent = useCallback((searchRow: GridRowParams<ITripSearchData>) => {
        return (
            <DetailPanelContent tData={searchRow.row} showSim={showSim} />
        );
    }, []);
    const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
        if (newRowSelectionModel.length > 1) {
            newRowSelectionModel = [newRowSelectionModel[newRowSelectionModel.length - 1]];
        }
        setRowSelectionModel(newRowSelectionModel);
    }

    const DeactivateButton = () => {
        if (!rowSelectionModel.length) return null;
        return (
            <span className="text-error" onClick={() => deactivateTrip()} style={{ cursor: 'pointer', padding: '4px 5px' }}>
                <FaTrash style={{ marginRight: '8px' }} /> DEACTIVATE
            </span>
        );
    };
    const SimulateButton = () => {
        if (!rowSelectionModel.length || rowSelectionModel.length > 1) return null;
        const selectedRowsData: (ITripSearchData | undefined)[] = rowSelectionModel.map((selectedId: any) => {
            return searchTripRowData.find((row: any) => row.id === selectedId);
        });
        // console.log(selectedRowsData);
        const data: ITripSearchData | undefined = selectedRowsData?.find((d: any) => {
            return (d?.cancelled || d?.landed)
        });
        if (data) return null;

        return (
            <span className="custom-grid-toolbar-btn" onClick={() => simulateTrip()} style={{ cursor: 'pointer', padding: '4px 5px' }}>
                <FaWindowRestore style={{ marginRight: '8px' }} /> SIMULATE
            </span>
        );
    };
    const DownloadButton = () => {
        return (
            <span className="custom-grid-toolbar-btn" onClick={() => downloadSearch()} style={{ cursor: 'pointer', padding: '4px 5px' }}>
                <FaDownload style={{ marginRight: '8px' }} /> DOWNLOAD
            </span>
        );
    };
    const CustomTripToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                {/* <DownloadButton /> */}
                <DeactivateButton />
                {showSim && <SimulateButton />}
            </GridToolbarContainer>
        );
    }

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
    const handleDetailPanelExpandedRowIdsChange = useCallback(
        (newIds: GridRowId[]) => {
            if (newIds?.length > 1) newIds = [newIds[newIds.length - 1]];
            setDetailPanelExpandedRowIds(newIds);
        },
        [],
    );

    return (
        <div className='container-fluid dashboard-container'>
            <div className='row'>

                {
                    !!selectedRow &&
                    <Modal
                        open={simModalFlag}
                        onClose={() => handleCloseSimulateModal()}
                    >
                        <Grid
                            container
                            maxWidth={'sm'}
                            margin='0px auto'
                            className={"custom-modal-grid"}
                        >
                            <SimulateTrip
                                trip={selectedRow}
                                onCloseModal={() => handleCloseSimulateModal()}
                            />
                        </Grid>
                    </Modal>
                }
                <CustomModal
                    headerName='Upload BP'
                    maxWidth={'sm'}
                    openModalFlag={uploadModalFlag}
                    onCloseModal={handleCloseUploadBPModal}
                >
                    <BPUploader pnr={selectedPnr} />
                </CustomModal>

                <div className='col-md-12'>
                    <h2>Search Trip</h2>
                </div>

                <div className='col-md-12'>
                    <div className='custom-search-wrapper'>
                        <SearchComponent advanceSearchCategories={true} onSearch={_onSearch} />
                        {/* <SearchComponent2 advanceSearchCategories={true} onSearch={_onSearch} /> */}
                    </div>
                </div>

                <div className='col-md-12'>
                    {!!searchDetails.text &&
                        <>
                            <p style={{ paddingTop: '40px' }}>
                                {searchDetails.text}
                            </p>
                            {(searchDetails.status === false) &&
                                <p className='no-margin' style={{ textAlign: 'center' }}>
                                    Search failed
                                </p>
                            }
                        </>
                    }
                    {
                        searchDetails.status &&
                        <div className='data-grid data-grid-large'>
                            {
                                searchDetails.type === "trip" &&
                                <DataGridPremium
                                    rows={searchTripRowData}
                                    columns={searchTripColData}
                                    density='compact'
                                    slots={{
                                        toolbar: CustomTripToolbar,
                                        pagination: () => <CustomGridPagination isZeroBased={true} />,
                                    }}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => handleRowSelectionModelChange(newRowSelectionModel)}
                                    rowSelectionModel={rowSelectionModel}
                                    rowThreshold={0}
                                    // getDetailPanelHeight={getDetailPanelHeight}
                                    getDetailPanelHeight={({ row }) => 'auto'}
                                    getDetailPanelContent={getDetailPanelContent}
                                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                                    pagination
                                    paginationMode="server"
                                    rowCount={rowCountState}
                                    pageSizeOptions={[paginationModel.pageSize]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={(page: GridPaginationModel) => _onTripSearchFromGrid(page)}
                                />
                            }
                            {
                                searchDetails.type === "notification" &&
                                <DataGridPremium
                                    rows={searchNotificationRowData}
                                    columns={searchNotificationColData}
                                    density='compact'
                                    slots={{
                                        toolbar: CustomTripToolbar,
                                        pagination: () => <CustomGridPagination isZeroBased={true} />,
                                    }}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    rowThreshold={0}
                                    pagination
                                    paginationMode="server"
                                    rowCount={rowCountState}
                                    pageSizeOptions={[paginationModel.pageSize]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={(page: GridPaginationModel) => _onNotificationSearchFromGrid(page)}
                                />
                            }
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default FlightWatcherSearch;