
import { IVendorData, ITemplateData, ICommunicationPreferences, ITripRequest, IMissingInfoPayload, IProductFeature, IVendorProfile } from "_models/vendor.interface";
import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";
import { StorageProvider } from "./storageProvider.service";
import { IStorage } from "_models/storage.interface";
import { AppConstants } from "_constants/App.constants";
import { IAuthToken } from "_models/common.interface";
import { UtilSvc } from "./util.service";
import { GridPaginationModel } from "@mui/x-data-grid";
import { DisplayOption } from "_models/flightDboard.interface";
import { IFlightSegmentFSBMain } from "_models/booking.interface";
import { IFsbFlightSegment } from "_models/fsb.interface";

export class VendorMgmntSvc extends UrlProviderService {
    private static instance: VendorMgmntSvc;
    private utilSvc = UtilSvc.getInstance();
    private constructor() {
        super('vendor-mgmnt-api');
    }
    public static getInstance(): VendorMgmntSvc {
        if (!this.instance) {
            this.instance = new VendorMgmntSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);
    private storageSvc: IStorage = StorageProvider.getStorage();

    private URL_PATH = {
        VENDORS: 'vendors',
        TRIPS: 'trips',
        PUBLIC: 'public',
    }

    vendorInfo(id?: string) {
        // console.log("vendorInfo");
        const uri = id ? this.generateUrl(`${this.URL_PATH.VENDORS}/me?vid=${id}`) : this.generateUrl(`${this.URL_PATH.VENDORS}/me`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getChildrenVendor() {
        const uri = this.generateUrl(`${this.URL_PATH.VENDORS}/children`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    createFlightWatcherCSV(file: FormData) {
        const uri = this.generateUrl('import/watcher');
        return this.apiSvc.post(uri, file, { setAuth: true });
    }
    createTripV2(payload: ITripRequest) {
        const uri = this.generateUrl(`${this.URL_PATH.VENDORS}/v2/subscription`);
        return this.apiSvc.post(uri, payload, { setAuth: true });
    }
    simulate(payload: any, queryParam: string | null, onDemandFS: boolean | undefined) {
        let url = `${this.URL_PATH.TRIPS}/simulatefs`;
        if (queryParam) url = url + `?delayInMinutes=${queryParam}`;
        if (onDemandFS !== undefined) {
            url = url + (url.includes('?') ? `&` : `?`) + `onDemandFS=${onDemandFS}`;
        }
        const uri = this.generateUrl(url);
        return this.apiSvc.post(uri, payload, { setAuth: true });
    }
    searchTrip(payload: any, page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.TRIPS}/search?page=${page.page + 1}&size=${page.pageSize}&searchStr=${this.utilSvc.safePayload(payload)}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    fetchUpcomingTrip(days: number, page: GridPaginationModel, queryData: string) {
        const uri = this.generateUrl(`travel/trips/upcoming?page=${page.page}&size=${page.pageSize}&nextDays=${days}${queryData ? `&${queryData}` : ''}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    deactivateTrip(bookingnum: string, productCode: string) {
        const uri = this.generateUrl(`${this.URL_PATH.TRIPS}/${bookingnum}?productCode=${productCode}`);
        return this.apiSvc.delete(uri, { setAuth: true });
    }
    downloadTripSearch(payload: any) {
        const uri = this.generateUrl(`${this.URL_PATH.TRIPS}/search?searchStr=${this.utilSvc.safePayload(payload)}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getSearchFields(searchType: string) {
        const uri = this.generateUrl(`products/fields?type=${searchType}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    createVendor(vendorData: IVendorData) {
        const uri = this.generateUrl(`${this.URL_PATH.VENDORS}`);
        return this.apiSvc.post(uri, vendorData, { setAuth: true });
    }
    getVendors() {
        const uri = this.generateUrl(`${this.URL_PATH.VENDORS}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    uploadTemplate(templateData: ITemplateData, vid?: string) {
        const uri = vid ? this.generateUrl(`${this.URL_PATH.VENDORS}/templates?vid=${vid}`) : this.generateUrl(`${this.URL_PATH.VENDORS}/templates`);
        return this.apiSvc.put(uri, templateData, { setAuth: true });
    }
    updatePreferences(preferences: ICommunicationPreferences, id?: string) {
        const uri = id ? this.generateUrl(`${this.URL_PATH.VENDORS}/preferences?vid=${id}`) : this.generateUrl(`${this.URL_PATH.VENDORS}/preferences`);
        return this.apiSvc.put(uri, preferences, { setAuth: true });
    }

    getMissingPassengerInfo(tripToken: string, bookingNo: string) {
        const uri = this.generateUrl(`${this.URL_PATH.PUBLIC}/passportinfo/${tripToken}?bookingNo=${bookingNo}`);
        return this.apiSvc.get(uri);
    }
    updateMissingPassengerInfo(tripToken: string, payload: IMissingInfoPayload) {
        const uri = this.generateUrl(`${this.URL_PATH.PUBLIC}/passportinfo/${tripToken}`);
        return this.apiSvc.post(uri, payload);
    }

    getTokenData(): IAuthToken | null {
        const token: string | null = this.storageSvc.get(AppConstants.TOKEN.AUTH);
        if (token) {
            const tokenData: IAuthToken = JSON.parse(atob(token.split('.')[1]));
            return tokenData;
        }
        return null;
    }
    getRole(): string {
        let role = '';
        const tokenData = this.getTokenData();
        if (tokenData) {
            role = tokenData.scope?.toUpperCase()
        }
        return role;
    }

    getProductsFeatures() {
        const uri = this.generateUrl(`products/withfeatures`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    async fetchProductsFeaturesInfo(): Promise<any> {
        const productFeaturesLS: string = AppConstants.LOCAL_STORAGE_KEY.PRODUCTS_FEATURES_DATA;
        let productsFeaturesData: any = this.storageSvc.get(productFeaturesLS);
        if (!productsFeaturesData) {
            this.storageSvc.put(productFeaturesLS, []);
            productsFeaturesData = [];
        }
        return new Promise((resolve, reject) => {
            if (productsFeaturesData && productsFeaturesData.length) {
                resolve(productsFeaturesData)
            } else {
                this.getProductsFeatures()
                    .then((res: any) => {
                        if (res?.data) {
                            // console.log(res.data)
                            this.storageSvc.put(productFeaturesLS, res.data);
                            resolve(res.data);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(null);
                    })
            }
        })
    }

    createTripUploaderCSV(file: FormData) {
        const uri = this.generateUrl(`import/subscriptions`);
        return this.apiSvc.post(uri, file, { setLoader: false, setAuth: true });
    }

    vendorRouteGuard = (vendorInfo: IVendorProfile | undefined, tokenPayload: IAuthToken | null | undefined, data: string | string[], dataType: 'emailId' | 'productId'): boolean => {
        switch (dataType) {
            case 'emailId':
                // const email = vendorInfo?.email;
                const email = tokenPayload?.vendor;
                if (!email) return false;
                if (data.includes(email)) return true;
                return false;
            case 'productId':
                const productFeatures = vendorInfo?.productFeatures;
                if (!productFeatures) return false;
                if (!!productFeatures.find((p: IProductFeature) => p.id === data)) return true;
                return false;
            default:
                return false;
        }
    }

    fetchUpcomingTripsDisplayType(): DisplayOption | null {
        const key = AppConstants.LOCAL_STORAGE_KEY.UPCOMING_TRIPS_DISPLAY_TYPE;
        const value = this.storageSvc.get(key);
        if (value && (value === 'card' || value === 'grid')) {
            return value;
        }
        return null;
    }
    saveUpcomingTripsDisplayType(display: DisplayOption) {
        const key = AppConstants.LOCAL_STORAGE_KEY.UPCOMING_TRIPS_DISPLAY_TYPE;
        this.storageSvc.post(key, display);
    }

    fetchBillingReport(from: string, to: string, vid: string, productType: string, deactivated: boolean) {
        const uri = this.generateUrl(`${this.URL_PATH.TRIPS}/report?from=${from}&to=${to}&vid=${vid}&productType=${productType}&deactivated=${deactivated}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    submitAlternateFlight(data: IFlightSegmentFSBMain[] | undefined, searchid: string, bookingno: string, selection: "NO_SELECTION" | "LOUNGE_PASS" | "ALTERNATIVE_FLIGHTS") {
        const uri = this.generateUrl(`${this.URL_PATH.PUBLIC}/segment/reselect?searchid=${searchid}&bookingno=${bookingno}&selection=${selection}`);
        return this.apiSvc.post(uri, data);
    }
    submitAlternateFlightFsb(data: IFsbFlightSegment[] | undefined, searchid: string, bookingno: string, selection: "NO_SELECTION" | "LOUNGE_PASS" | "ALTERNATIVE_FLIGHTS") {
        const uri = this.generateUrl(`${this.URL_PATH.PUBLIC}/segment/reselect?searchid=${searchid}&bookingno=${bookingno}&selection=${selection}`);
        return this.apiSvc.post(uri, data);
    }
    getTripInfoBySearchId(searchid: string) {
        const uri = this.generateUrl(`${this.URL_PATH.PUBLIC}/search/${searchid}`);
        return this.apiSvc.get(uri);
    }
}